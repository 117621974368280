import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Modal, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavbarComponent() {
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation(); // Megkapjuk az i18n objektumot

  // Alapértelmezett nyelv beállítása HU-ra, ha még nincs nyelv választva
  useEffect(() => {
    if (!localStorage.getItem('i18nextLng')) {
      i18n.changeLanguage('hu');
    }
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Nyelv váltása
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar bg="light" expand="lg" className="mb-4">
        <Container>
          <Navbar.Brand as={Link} to="/">speiz.hu</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">{t('Főoldal')}</Nav.Link>
              <Nav.Link as={Link} to="/about">{t('Rólunk')}</Nav.Link>
              <Nav.Link as={Link} to="/menu">{t('Menü')}</Nav.Link>
              <Nav.Link as={Link} to="/contact">{t('Kapcsolat')}</Nav.Link>
              <Nav.Link as={Link} to="/faq">{t('Gyakran Ismételt Kérdések')}</Nav.Link>
              <Nav.Link as={Link} to="/privacy-policy">{t('Adatvédelmi irányelvek')}</Nav.Link>
            </Nav>
            <Button variant="primary" onClick={handleShow}>
              {t('Asztalfoglalás')}
            </Button>
            <Nav className="ms-3">
              <Nav.Link onClick={() => changeLanguage('hu')}>HU</Nav.Link>
              <Nav.Link onClick={() => changeLanguage('en')}>EN</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Asztalfoglalás')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('Foglalási űrlap tartalma...')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Bezárás')}
          </Button>
          <Button variant="primary" onClick={handleClose}>
            {t('Foglalás elküldése')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NavbarComponent;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <Container>
        <h1>{t('Rólunk')}</h1>
        <p>{t('Üdvözöljük a weboldalunkon!')}</p>
      </Container>
    </div>
  );
}

export default Home;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "Főoldal": "Home",
      "Rólunk": "About",
      "Menü": "Menu",
      "Kapcsolat": "Contact",
      "Gyakran Ismételt Kérdések": "FAQ",
      "Adatvédelmi irányelvek": "Privacy Policy",
      "Asztalfoglalás": "Reservation",
      "Üdvözöljük a weboldalunkon!": "Welcome to our website!",
      "Bezárás": "Close",
      "Foglalás elküldése": "Send reservation",
      "Foglalási űrlap tartalma...": "Reservation form content...",
      "ez egy teszt": "this is a test"
    }
  },
  hu: {
    translation: {
      "Főoldal": "Főoldal",
      "Rólunk": "Rólunk",
      "Menü": "Menü",
      "Kapcsolat": "Kapcsolat",
      "Gyakran Ismételt Kérdések": "Gyakran Ismételt Kérdések",
      "Adatvédelmi irányelvek": "Adatvédelmi irányelvek",
      "Asztalfoglalás": "Asztalfoglalás",
      "Üdvözöljük a weboldalunkon!": "Üdvözöljük a weboldalunkon!",
      "Bezárás": "Close",
      "Foglalás elküldése": "Foglalás elküldése",
      "Foglalási űrlap tartalma...": "Foglalási űrlap tartalma...",
      "ez egy teszt": "ez egy teszt"
    }
  }
};

i18n
  .use(LanguageDetector) // Használjuk a nyelvdetektort
  .use(initReactI18next) // Kötés a react-i18next-hez
  .init({
    resources,
    fallbackLng: 'hu', // Alapértelmezett nyelv, ha nem található a localStorage-ban
    interpolation: {
      escapeValue: false // React automatikusan escape-el
    },
    detection: {
      order: ['localStorage', 'navigator'], // Először a localStorage-t, majd a böngésző nyelvét nézi
      caches: ['localStorage'], // Tárolja a nyelvválasztást a localStorage-ban
    }
  });
